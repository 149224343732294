import React from "react";
import { withRouter } from "react-router-dom";

import axios from "axios";
import config from "../utils";
const api = axios.create({
  withCredentials: true
});

const PrimaryButton = ({ text, username, password, match }) => {
  const handleLogin = () => {
    api
      .post(`${config.authUrl}/get/refresh/key`, {
        username: username,
        password: password
      })
      .then((res) => {
        const refreshKey = res.data;
        window.location.href = `${
          config.authUrl
        }/login/${refreshKey}?redirect_to=https://${atob(
          match.params.callbackUrl
        )}`;
      })
      .catch((err) => console.error(err));
  };

  return (
    <input
      type="submit"
      value={text}
      onClick={handleLogin}
      data-wait="Please wait..."
      className="cc-auth-sumbit-btn cs-lr-active w-button"
    />
  );
};

export default withRouter(PrimaryButton);
