import React from "react";
import { withRouter } from "react-router-dom";

const GoogleButton = (props) => {
  return (
    <a href="#" className="cc-auth-socialicon w-inline-block">
      <img
        src={require("../assets/images/google-1772223-1507807.png")}
        alt=""
        className="cc-auth-signin-gico"
      />
    </a>
  );
};

export default withRouter(GoogleButton);
