import React, { useState } from "react";
import EmailPhoneInput from "../components/EmailPhoneInput";
import PasswordInput from "../components/PasswordInput";
import PrimaryButton from "../components/PrimaryButton";
import GoogleButton from "../components/GoogleButton";
import FacebookButton from "../components/FacebookButton";
import AppleButton from "../components/AppleButton";
import FooterLink from "../components/FooterLink";
import Container from "../components/Container";
import SocialsContainer from "../components/SocialsContainer";

const Login = (props) => {
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);

  return (
    <Container label="Login">
      <EmailPhoneInput
        setEmail={(data) => setEmail(data)}
        setPhone={(data) => setPhone(data)}
      />
      <PasswordInput
        setPassword={(data) => setPassword(data)}
        keepLoggedIn={keepLoggedIn}
        setKeepLoggedIn={(bool) => setKeepLoggedIn(bool)}
        goToOtp={() => props.history.push("/otp", { phone: phone })}
      />
      <PrimaryButton username={email} password={password} text="Log In" />
    </Container>
  );
};

export default Login;
